<template>
  <div>
    <b-row>
      <b-col cols="12">
        <b-card no-body>
          <b-row align-h="between" align-v="center" class="px-1 pt-2 pb-1">
            <b-col cols="auto">
              <h3 class="m-0">Datos empresa</h3>
            </b-col>
          </b-row>
          <!-- header -->
          <hr class="m-0" />
          <b-row class="px-1 pt-2 pb-1">
            <b-col cols="auto">
              <validation-observer ref="editConfig">
                <b-form
                  class="mt-2"
                  @submit.prevent="handleSubmit"
                  @keydown.enter="handleSubmit"
                >
                  <b-row>
                    <!-- name -->
                    <b-col sm="6">
                      <b-form-group
                        :label="'Nombre empresa'"
                        label-for="account-name"
                      >
                        <b-form-input
                          v-model="company_name"
                          name="company_name"
                          :placeholder="'Nombre empresa'"
                        />
                      </b-form-group>
                    </b-col>
                    <!-- cif -->
                    <b-col sm="6">
                      <b-form-group :label="'CIF'" label-for="account-cif">
                        <b-form-input
                          v-model="cif"
                          name="cif"
                          :placeholder="'CIF'"
                        />
                      </b-form-group>
                    </b-col>

                    <!-- email -->
                    <b-col sm="6">
                      <validation-provider
                        #default="{ errors }"
                        :name="'Email'"
                        rules="required|email"
                      >
                        <b-form-group
                          :label="'Email'"
                          label-for="account-e-mail"
                        >
                          <b-form-input
                            v-model="email"
                            name="email"
                            :placeholder="'Email'"
                          />
                          <small ref="errorField" class="text-danger">{{
                            errors[0]
                          }}</small>
                        </b-form-group>
                      </validation-provider>
                    </b-col>
                    <!-- phone -->
                    <b-col sm="6">
                      <b-form-group
                        :label="'Teléfono'"
                        label-for="account-phone"
                      >
                        <b-form-input
                          v-model="phone"
                          name="phone"
                          :placeholder="'Teléfono'"
                        />
                      </b-form-group>
                    </b-col>

                    <!-- address -->
                    <b-col sm="6">
                      <b-form-group
                        :label="'Dirección'"
                        label-for="account-direction"
                      >
                        <b-form-input
                          v-model="direction"
                          :placeholder="'Dirección'"
                          name="direction"
                        />
                      </b-form-group>
                    </b-col>
                    <!-- cp -->
                    <b-col sm="6">
                      <b-form-group
                        :label="'Código postal'"
                        label-for="account-cp"
                      >
                        <b-form-input
                          v-model="cp"
                          name="cp"
                          :placeholder="'Código postal'"
                        />
                      </b-form-group>
                    </b-col>
                    <!-- town -->
                    <b-col sm="6">
                      <b-form-group :label="'Pueblo'" label-for="account-town">
                        <b-form-input
                          v-model="town"
                          name="town"
                          :placeholder="'Pueblo'"
                        />
                      </b-form-group>
                    </b-col>
                    <!-- province -->
                    <b-col sm="6">
                      <b-form-group
                        :label="'Provincia'"
                        label-for="account-province"
                      >
                        <b-form-input
                          v-model="province"
                          name="province"
                          :placeholder="'Provincia'"
                        />
                      </b-form-group>
                    </b-col>
                    <!-- country -->
                    <b-col sm="6">
                      <b-form-group
                        :label="'Ciudad'"
                        label-for="account-country"
                      >
                        <b-form-input
                          v-model="country"
                          name="country"
                          :placeholder="'Ciudad'"
                        />
                      </b-form-group>
                    </b-col>

                    <!-- web -->
                    <b-col sm="6">
                      <b-form-group :label="'Web'" label-for="account-web">
                        <b-form-input
                          v-model="web"
                          name="web"
                          :placeholder="'Web'"
                        />
                      </b-form-group>
                    </b-col>
                    <!-- pedido mayor que -->
                    <b-col sm="6">
                      <b-form-group :label="'Pedido mayor que'" label-for="Pedido mayor que">
                        <b-form-input
                          v-model.number="order_max_amount"
                          name="Pedido mayor que"
                          :placeholder="'Pedido mayor que'"
                        />
                      </b-form-group>
                    </b-col>
                    <!-- margen -->
                    <b-col sm="6">
                      <b-form-group
                        :label="'Margen de venta sobre tarifas de transporte (%)'"
                        label-for="account-margen"
                      >
                        <b-form-input
                          v-model="margen"
                          name="margen"
                          :placeholder="'Margen'"
                          max="100"
                          min="0"
                        />
                      </b-form-group>
                    </b-col>
                    <b-col cols="12 text-right">
                      <b-button
                        type="submit"
                        variant="primary"
                        class="mt-2 mr-1 text-uppercase"
                      >
                        {{ "Guardar" }}
                      </b-button>
                    </b-col>
                  </b-row>
                </b-form>
              </validation-observer>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import {
  BButton,
  BForm,
  BFormGroup,
  BRow,
  BCol,
  BCard,
  BFormInput,
  BLink,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import Ripple from "vue-ripple-directive";

export default {
  components: {
    BLink,
    BButton,
    BForm,
    BFormGroup,
    BRow,
    BCol,
    BCard,
    ValidationProvider,
    ValidationObserver,
    BFormInput,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      company_name: "",
      phone: "",
      email: "",
      direction: "",
      cif: "",
      web: "",
      cp: "",
      town: "",
      province: "",
      country: "",
      margen: "",
      order_max_amount: 0
    };
  },
  computed: {
    ...mapGetters({
      config: "config/getConfig",
    }),
  },
  methods: {
    ...mapActions({
      edit: "config/edit",
      getConfig: "config/getConfig",
    }),
    handleSubmit() {
      this.$refs.editConfig.validate().then((success) => {
        if (success) {
          const formData = this.createFormData();
          if (formData) {
            this.edit({ config: formData });
          } else {
            this.$refs.errorField.scrollIntoView({
              behavior: "smooth",
              block: "end",
            });
          }
        }
      });
    },
    setData() {
      this.company_name = this.config.name;
      this.email = this.config.email;
      this.phone = this.config.phone;
      this.direction = this.config.direction;
      this.cif = this.config.cif;
      this.web = this.config.web;
      this.town = this.config.town;
      this.province = this.config.province;
      this.country = this.config.country;
      this.cp = this.config.cp;
      this.margen = this.config.margen;
      this.order_max_amount = this.config.pedido_mayor_que
    },
    createFormData() {
      const data = new FormData();
      data.append("name", this.company_name);
      data.append("email", this.email);
      data.append("phone", this.phone);
      data.append("direction", this.direction);
      data.append("cif", this.cif);
      data.append("web", this.web);
      data.append("town", this.town);
      data.append("province", this.province);
      data.append("country", this.country);
      data.append("cp", this.cp);
      data.append("margen", this.margen);
      data.append("pedido_mayor_que", this.order_max_amount);

      return data;
    },
  },
  async created() {
    await this.getConfig();
    await this.setData();
  },
};
</script>
